import ls from "./ls";
import Vue from "vue";

const pageBlackList = ["login", "enter"]; // 不收录的 页面 名单

/**
 * 打开菜单
 *
 * @param {*} vm
 * @param {*} meta
 * @param {*} name
 * @param {*} path
 * @param {*} params
 * @param {*} query
 * @param {*} cid
 * @returns
 */
export function openPage(vm, meta, name, path, params, query, cid, title = "首页") {
  if (name == null || name == "" || pageBlackList.includes(name)) {
    return;
  }

  const pagelist = vm.$store.state.page.pagelist;
  if (pagelist.length == 0) {
    let cachepagelist = ls.get(Vue.prototype.__SYSTEMCODE + "_pagelist");
    if (cachepagelist != null) {
      vm.$store._actions["page/initPage"][0](cachepagelist);
    }

    if (pagelist.length == 0) {
      vm.$store._actions["page/addPage"][0]({
        title,
        open: true,
        name: "home",
        path: "/home",
        closeable: false,
        cid: cid,
      });
    }
  }
  const newTitle = path === "/home" ? title : query.menuTitle || (query.no ? meta.title + "-" + query.no : meta.title);
  vm.$store._actions["page/addPage"][0]({
    title: newTitle,
    name,
    path,
    params,
    query,
    open: true,
    closeable: true,
    cid,
  });
}

/**
 * 刷新页面
 * @param {*} vm
 * @param {*} to
 */
export function refreshPageData(vm, to) {
  if (vm.$store.state.msg.msg["refresh"] && vm.$store.state.msg.msg["refresh"] == to.name) {
    if (to.matched[0].instances.content && to.matched[0].instances.content.__refresh) {
      to.matched[0].instances.content.__refresh(); //刷新页面数据
      vm.$store._actions["msg/readMsg"][0]("refresh"); //清除消息
    }
  }
}

/**
 * 路由访问记录
 * @param {*} vm vue 实例
 * @param {*} to  路由信息
 */
export function operationLog(vm, to) {
  if (vm._LOGGGER && vm.$store.state.user.userinfo.id) {
    // 已登录
    vm.$http.post("api/SysOperationLogService/recordLog", {
      type: "菜单访问",
      name: to.meta.title,
      pageUrl: to.path,
      pageName: to.meta.title,
      userId: vm.$store.state.user.userinfo.id,
    });
  }
}
/**
 * 菜单点击记录
 * @param {*} vm
 * @param {*} menuId
 */
export function menuClickLog(vm, menuId) {
  if (vm.$store.state.user.userinfo.id) {
    // 已登录
    vm.$http.post("api/UserMenuConfigService/saveUserMenuLog", {
      menuId: menuId,
      userId: vm.$store.state.user.userinfo.id,
    });
  }
}

/**
 *  按钮操作记录
 * @param {*} vm vue 实例
 * @param {*} btnText 按钮名称
 */
export function btnClickLog(vm, btnText) {
  if (btnText == ">>") {
    btnText = "赋权";
  }
  if (vm._LOGGGER && vm.$store && vm.$store.state.user.userinfo.id) {
    // 已登录
    vm.$http.post("api/SysOperationLogService/recordLog", {
      type: "按钮点击",
      name: btnText,
      pageUrl: vm.$route.path,
      pageName: vm.$route.meta.title,
      userId: vm.$store.state.user.userinfo.id,
    });
  }
}
/**
 * 接口调用记录
 * @param {*} user 用户信息
 * @param {string} url 接口url
 * @param {*} param 接口参数
 */
export function ajaxLog(user, url, param) {
  if (1 == 2 && Vue.prototype._LOGGGER && user.userinfo.id) {
    // 已登录
    const url_arr = url.split("/");
    const uri = url_arr[url_arr.length - 1];

    if (uri == "recordLog") {
      return;
    }
    Vue.http.post("api/SysOperationLogService/recordLog", {
      type: "接口调用",
      apiUrl: url,
      apiParams: decodeURIComponent(param),
      userId: user.userinfo.id,
    });
  }
}
/**
 * 脚本报错记录
 * @param {*} user 用户信息
 * @param {*} path 报错页面路由
 * @param {*} msg 报错信息
 */
export function errorLog(user, path, msg, info) {
  // if (Vue.prototype._LOGGGER && user.userinfo.id) { // 已登录
  //   const u = window.navigator.userAgent
  //   const b = getBrowser(u)
  //   const p = window.navigator.platform
  //   const vp = {
  //     w: window.innerWidth,
  //     h: window.innerHeight
  //   }
  //   Vue.http.post('recordErrorLog', {
  //     errorPath: path,
  //     info: info,
  //     stack: msg.stack,
  //     userAgent: u,
  //     browser: b,
  //     platform: p,
  //     view: JSON.stringify(vp),
  //     userId: user.userinfo.id
  //   })
  // }
}

export function getTransformXValue(element) {
  let s = element.style["transform"].indexOf("(");
  let e = element.style["transform"].indexOf(")");
  let arr = element.style["transform"]
    .substr(s + 1, e - (s + 1))
    .replace(/[^0-9\-.,]/g, "")
    .split(",");
  return isNaN(parseFloat(arr[0])) ? 0 : parseFloat(arr[0]);
}

export function getBrowser(userAgent) {
  // 判断是否IE浏览器
  if (userAgent.indexOf("Trident") > -1) {
    return "IE";
  }
  // 判断是否Edge浏览器
  if (userAgent.indexOf("Edge") > -1) {
    return "Edge";
  }
  // 判断是否Chorme浏览器
  if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  }
  // 判断是否Safari浏览器
  if (userAgent.indexOf("Safari") > -1) {
    return "Safari";
  }
  // 判断是否FireFox浏览器
  if (userAgent.indexOf("Firefox") > -1) {
    return "FF";
  }
}
export function arrayRemove(arr, from, to) {
  var rest = arr.slice((to || from) + 1 || arr.length);
  arr.length = from < 0 ? arr.length + from : from;
  return arr.push.apply(arr, rest);
}

export function isEmptyObject(obj) {
  if (obj == null) {
    return true;
  }
  var arr = Object.keys(obj);
  return arr.length === 0;
}

// 日期格式化
// (new Date()).Format("yyyy-MM-dd HH:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d H:m:s.S")      ==> 2006-7-2 8:9:4.18
export function dateFormat(date, fmt) {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "H+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  return fmt;
}

// 获取日期范围
export function getDateRange(dateNow, intervalDays) {
  const oneDayTime = 24 * 60 * 60* 1000;
  const lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
  return [formateDate(lastDay), formateDate(dateNow)];
}

// 日期转换
export function formateDate(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month > 0 ? month : '0' + month;
  day = day > 0 ? day : '0' + day;
  return year + '-' + month + '-' + day;
}

// 日期操作
// var  now  =  new  Date();
//  var  newDate  =  dateAdd("d",-10,now);//当前天数减少10天的时间
export function dateAdd(interval, number, date) {
  switch (interval) {
    case "y": {
      date.setFullYear(date.getFullYear() + number);
      break;
    }
    case "m": {
      date.setMonth(date.getMonth() + number);
      break;
    }
    case "d": {
      date.setDate(date.getDate() + number);
      break;
    }
    case "h": {
      date.setHours(date.getHours() + number);
      break;
    }
    case "i": {
      date.setMinutes(date.getMinutes() + number);
      break;
    }
    case "s": {
      date.setSeconds(date.getSeconds() + number);
      break;
    }
    default: {
      date.setDate(date.getDate() + number);
      break;
    }
  }
  return date;
}

export function getMonthByTwoTimes(s_date, e_date) {
  s_date = new Date(s_date);
  e_date = new Date(e_date);
  var a_31month = [1, 3, 5, 7, 8, 10, 12]; //31天的月份，2月份也暂时按30天计算
  var pl_month = e_date.getMonth() == 0 ? 12 : e_date.getMonth();
  var t_prelastmonthdays = a_31month.indexOf(pl_month) >= 0 ? 31 : 30; //结束月上个月的天数
  var t_lastmonthdays = a_31month.indexOf(e_date.getMonth() + 1) >= 0 ? 31 : 30; //结束月的天数
  var predictnum = 0;

  var t_year = e_date.getFullYear() - s_date.getFullYear();
  var t_month = e_date.getMonth() - s_date.getMonth();
  var t_day = e_date.getDate() + 1 - s_date.getDate(); //结束日期比开始日期少一天，算一整月
  if (t_day < 0) {
    t_month = t_month - 1;
    predictnum = (t_prelastmonthdays + t_day) / t_lastmonthdays; //多出的不足一个月的天数除以结束月的天数
  } else {
    predictnum = t_day / t_lastmonthdays;
  }
  return t_year * 12 + t_month + predictnum;
}

export function getDaysBetween(s_time, e_time) {
  var startDate = Date.parse(s_time);
  var endDate = Date.parse(e_time);
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}

/**
 * 获取传日日期的月尾日期
 */
export function getMonthEndDate(monthDate) {
  const nowYear = monthDate.getFullYear();
  const nowMonth = monthDate.getMonth();
  return new Date(nowYear, nowMonth, getMonthDays(nowMonth));
  止;
}

/**
 * 获取上月起止日期
 */
export function getLastMonth() {
  const nowYear = new Date().getFullYear(); //当前年
  const lastMonthDate = new Date(); //上月日期
  lastMonthDate.setDate(1);
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  var lastMonth = lastMonthDate.getMonth();
  return [new Date(nowYear, lastMonth, 1), new Date(nowYear, lastMonth, getMonthDays(lastMonth))]; //起 ，止
}

export function getMonthDays(myMonth) {
  const nowYear = new Date().getFullYear(); //当前年
  var monthStartDate = new Date(nowYear, myMonth, 1);
  var monthEndDate = new Date(nowYear, myMonth + 1, 1);
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
  return days;
}
/**
 * 获取本月起止日期
 */
export function getCurrentMonth() {
  const nowYear = new Date().getFullYear();
  const nowMonth = new Date().getMonth();
  return [new Date(nowYear, nowMonth, 1), new Date(nowYear, nowMonth, getMonthDays(nowMonth))]; //起 ，止
}

/**
 * 上周的起止日期
 */
export function getLastWeek() {
  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();
  const nowDayOfWeek = now.getDay(); //今天本周的第几
  return [
    new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 7),
    new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek - 7)),
  ]; //起 ，止
}

/**
 * 本周的起止日期
 */
export function getCurrentWeek() {
  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();
  const nowDayOfWeek = now.getDay();
  return [new Date(nowYear, nowMonth, nowDay - nowDayOfWeek), new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek))]; //起 ，止
}

/**
 * 去同类键 的值  返回 对象
 * @param {*} obj
 * @param {*} ksome
 */
export function getObjV(obj, ksome) {
  let values = {};
  for (const k in obj) {
    if (k.indexOf(".") > -1 && k.split(".")[0] === ksome) {
      if (obj[k] != null && obj[k] != "") {
        values[k.split(".")[1]] = obj[k];
      }
    }
  }

  return values;
}

/**
 * 将obj中的带.ksome的属性值抽取出来
 * 返回一个对象类型
 * 如：{vhc.le1:'', vhc.le2:'' ...}, ksome=[vhc]
 * 返回：{le1:'', le2:'' ... }一个值对象
 *
 * @param {} obj
 * @param {*} ksome
 * @returns
 */
export function getObjV2(obj, ksome) {
  let values = {};
  for (const k in obj) {
    if (k.indexOf(".") > -1) {
      let tmpArr = k.split(".");
      let temK = tmpArr.pop();
      if (tmpArr.join(".") === ksome) {
        if (obj[k] != null && obj[k] != "") {
          values[temK] = obj[k];
        }
      }
    }
  }

  return values;
}

export function getStepsValue(steps, kname) {
  for (const step of steps) {
    console.log(step.name);
    if (kname.split(".")[0] === step.name) {
      for (const k in step.values) {
        if (kname.split(".")[1] === k) {
          return step.values[k];
        }
      }
      for (const k in step.stringValues) {
        if (kname.split(".")[1] === k) {
          return step.stringValues[k];
        }
      }
    }
  }
}

export function getStepsValue2(steps, kname) {
  let tmpArr = kname.split(".");
  let temK = tmpArr.pop();
  const key = tmpArr.join(".");
  for (const step of steps) {
    if (key === step.name) {
      for (const k in step.values) {
        if (temK === k) {
          return step.values[k];
        }
      }

      if (step.stringValues) {
        for (const k in step.stringValues) {
          if (temK === k) {
            return step.stringValues[k];
          }
        }
      }
    }
  }
}
export function getObjectMap(obj, nbj, pre) {
  Object.keys(nbj).forEach((k, idx) => {
    var tp = Object.prototype.toString.call(nbj[k]);
    if (tp == "[object Object]") {
      getObjectMap(obj, nbj[k], pre ? pre + "." + k : k);
    } else {
      obj[pre ? pre + "." + k : k] = nbj[k];
    }
  });

  return obj;
}

export function formatDate(date, fmt) {
  const o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return fmt;
}

/**
 *
 * @param {*} menu
 * @param {*} type ['manage','wlhy']
 */
export function findTypeInMenuType(menu) {
  if (menu && menu.$attrs) {
    if (["manage", "wlhy"].indexOf(menu.$attrs.mxtype) > -1) {
      return menu.$attrs.mxtype;
    } else {
      if (menu.parentMenu) {
        return findTypeInMenuType(menu.parentMenu);
      } else {
        return null;
      }
    }
  }
}

export function searchMenuByText(menu, keyword, rs, parentText, rootUrl) {
  for (const item of menu) {
    if (item.text && item.hidden == 0 && item.url && item.url != "" && item.text.indexOf(keyword) > -1) {
      //非隐藏，有url 的
      rs.push({
        text: item.text,
        url: item.url,
        id: item.id,
        parentText: parentText,
        rootUrl: rootUrl,
      });
    }
    if (item.children && item.mxtype != 'saas56') {
      searchMenuByText(
        item.children,
        keyword,
        rs,
        parentText ? parentText + " / " + item.text : item.text,
        rootUrl ? rootUrl : item.url.endsWith(".html") ? item.url : null,
      );
    }
  }
}

export function searchNewMenuByText2(menu, keyword, rs, parentText, rootUrl) {
  for (const item of menu) {
    if (item.text && item.hidden == 0 && item.url && item.url != "" && item.text.indexOf(keyword) > -1) {
      rs.push(item);
    }
    if (item.children) {
      let newUrl = "";
      if (rootUrl) {
        newUrl = rootUrl;
      } else {
        if (item.url) {
          newUrl = item.url.endsWith(".html") ? item.url : null;
        }
      }
      searchNewMenuByText2(item.children, keyword, rs, parentText ? parentText + " / " + item.text : item.text, newUrl);
    }
  }
}

/**
 * 树形数据转list
 * @param {*} data
 */
export function getListDataFromTree(tree, result) {
  for (const item of tree) {
    if (item.hidden == 0 && item.type == 2 && item.url && item.url != "") {
      result.push({
        value: item.id,
        label: item.text,
      });
    }

    if (item.children) {
      getListDataFromTree(item.children, result);
    }
  }
  //return data;
}
/**
 *
 * @param {*} menuList 菜单列表
 * @param {*} menuData 菜单树
 * @param {*} sys 系统编码
 */
export function filterMenu(menuList, menuData, sys) {
  let menu = [];
  let tree = [];
  for (const item of menuData) {
    if (item.mxtype == sys) {
      tree = item;
    }
  }
  for (const item of menuList) {
    let result = {
      has: false,
    };
    isMenuInTree(item, tree, result);
    if (result.has) {
      menu.push(item);
    }
  }

  return menu;
}

export function isMenuInTree(menu, node, result) {
  if (result.has) {
    return;
  }
  if (node.id) {
    if (node.id == menu.menu.id) {
      result.has = true;
    }
    if (node.children) {
      isMenuInTree(menu, node.children, result);
    }
  } else {
    for (var i = 0; i < node.length; i++) {
      var item = node[i];
      if (item.id == menu.menu.id) {
        result.has = true;
      }
      if (item.children) {
        isMenuInTree(menu, item.children, result);
      }
    }
  }
}

/**
 *
 * @param {*} pathname /manage.html 类似模块，名
 * @param {*} url /home等路由路径
 * @returns
 */
export function hasMenuAuthWithPathName(pathname, url) {
  const $menus = ls.get("menu");
  var $menusInPath = $menus.filter((m) => {
    return m.url == pathname;
  });
  var result = {
    hasPmss: false,
  };

  visitMenuTree($menusInPath, url, result);

  return result.hasPmss;
}

export function hasMenuAuth(url) {
  const $menus = ls.get("menu");
  var result = {
    hasPmss: false,
  };

  visitMenuTree($menus, url, result);

  return result.hasPmss;
}

export function visitMenuTree(nodes, url, result) {
  if (result.hasPmss == true) return;
  for (var i = 0; i < nodes.length; i++) {
    var node = nodes[i];

    if (node.url && url.indexOf(node.url) > -1) {
      // 当前页面路径，包含菜单路径即可
      result.hasPmss = true;
      continue;
    }

    if (node.children) {
      visitMenuTree(node.children, url, result);
    }
  }
}

export function indexOf(list, target, key) {
  if (typeof list != "undefined" && typeof target != "undefined") {
    for (var i = 0; i < list.length; i++) {
      if (list[i][key] == target[key]) {
        return i;
      }
    }
  }
  return -1;
}

export function addAll(listA, listB, key) {
  listA = listA || [];
  listB = listB || [];
  var all = [];
  for (var i = 0; i < listA.length; i++) {
    if (indexOf(all, listA[i], key) == -1) {
      all.push(listA[i]);
    }
  }
  for (var j = 0; j < listB.length; j++) {
    if (indexOf(all, listB[j], key) == -1) {
      all.push(listB[j]);
    }
  }
  return all;
}

export function copy(list) {
  var l = [];
  for (var i = 0; i < list.length; i++) {
    l.push(list[i]);
  }
  return l;
}

export function deepCopy(data) {
  const t = typeOf(data);
  let o;

  if (t === "array") {
    o = [];
  } else if (t === "object") {
    o = {};
  } else {
    return data;
  }

  if (t === "array") {
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]));
    }
  } else if (t === "object") {
    for (let i in data) {
      o[i] = deepCopy(data[i]);
    }
  }
  return o;
}

function typeOf(obj) {
  const toString = Object.prototype.toString;
  const map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  return map[toString.call(obj)];
}

export function flatNodes(data) {
  return data.reduce((res, node) => {
    if (!node.children) {
      res.push(node);
    } else {
      res.push(node);
      res = res.concat(flatNodes(node.children));
    }
    return res;
  }, []);
}
/**
 * 枚举
 * 
 *  const TestEnum = new Enum([
      {value:1,name:"新建"},
      {value:2,name:"审核"}
    ])
    TestEnum.新建
    TestEnum.valueOf('审核')
 */
export class Enum {
  constructor(arr) {
    let typeArr = [];

    if (!Array.isArray(arr)) {
      throw "构造参数必须是数组";
    }

    arr.map((item) => {
      if (!item.value || !item.name) {
        return;
      }

      typeArr.push(item.name);

      this[item.name] = item;
    });

    this.arr = arr;

    this.typeArr = typeArr;
  }

  valueOf(name) {
    return this[name].value;
  }

  values() {
    return this.arr;
  }
}

/**
 * 加法运算，避免数据相加小数点后产生多位数和计算精度损失。
 *
 * @param num1加数1 | num2加数2
 */
export function numAdd(num1, num2) {
  var baseNum, baseNum1, baseNum2;
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  return (num1 * baseNum + num2 * baseNum) / baseNum;
}
/**
 * 加法运算，避免数据相减小数点后产生多位数和计算精度损失。
 *
 * @param num1被减数 | num2减数
 */
export function numSub(num1, num2) {
  var baseNum, baseNum1, baseNum2;
  var precision; // 精度
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  precision = baseNum1 >= baseNum2 ? baseNum1 : baseNum2;
  return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
}
/**
 * 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
 *
 * @param num1被乘数 | num2乘数
 */
export function numMulti(num1, num2) {
  var baseNum = 0;
  try {
    baseNum += num1.toString().split(".")[1].length;
  } catch (e) {}
  try {
    baseNum += num2.toString().split(".")[1].length;
  } catch (e) {}
  return (Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", ""))) / Math.pow(10, baseNum);
}
/**
 * 除法运算，避免数据相除小数点后产生多位数和计算精度损失。
 *
 * @param num1被除数 | num2除数
 */
export function numDiv(num1, num2) {
  var baseNum1 = 0,
    baseNum2 = 0;
  var baseNum3, baseNum4;
  try {
    baseNum1 = num1.toString().split(".")[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split(".")[1].length;
  } catch (e) {
    baseNum2 = 0;
  }

  baseNum3 = Number(num1.toString().replace(".", ""));
  baseNum4 = Number(num2.toString().replace(".", ""));
  return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1);
}

const DelayTimeHolder = {};

/**
 * 延迟一个函数的执行
 *
 * @param {} action
 * @param {*} t
 * @param {*} delay
 * @returns
 */
export function delayFunc(action, timerHolder, delay = 600) {
  if (DelayTimeHolder[timerHolder] == null) {
    DelayTimeHolder[timerHolder] = -1;
    console.log(DelayTimeHolder[timerHolder]);
  }

  return function (nv, ov) {
    var thisObj = this;
    clearTimeout(DelayTimeHolder[timerHolder]);
    DelayTimeHolder[timerHolder] = setTimeout(() => {
      action.call(thisObj, nv);
    }, delay);
  };
}

/**
 * 返回需要导出得数据
 * @returns
 */
export function getExportNum() {
  return 5000;
}

export function formatNumberWithCommas(number, maxLength = null) {
     // 转换为字符串  
     let str = number.toString();  
      
     // 如果需要截断  
     if (str.length > maxLength) {  
         str = str.substring(0, maxLength);  
     }  
       
     // 如果需要添加前导零  
     while (str.length < maxLength) {  
         str = '0' + str;  
     }  
       
     // 插入逗号  
     let formatted = str.split('').reverse().join('').replace(/(\d{3})(?=\d)/g, '$1,').split('').reverse().join('');  
       
     return formatted;  
}
